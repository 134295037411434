import React, { Component } from 'react'
import wolfit from './images/wolfit_logo_small.png'
import tomas from './images/tomas_small_transparent_w_linkedin.png'
import aws_badge from './images/AWS-SolArchitect-Associate.png'

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  /**
   * Component Did Mount
   */

  async componentDidMount() {}

  /**
   * Render
   */

  render() {
    return (
        <div className="container">
            <img className="logo" alt="logo" src={wolfit} />
            <div className="tagline">
                <div className="text">Cloud Native</div>
            </div>
            <p></p>
            <div>
                <a className="content" href="https://www.linkedin.com/in/wolfit/">
                    <img className="photo" alt="Tomas Sjöberg" src={tomas} />
                </a>
                <div className="tagline">
                <div className="text">Tomas Sjöberg</div>
                <div className="text">AWS Solution Architect and Developer</div>
                <div><a className="content" href="https://www.youracclaim.com/users/tomas-sjoberg">
                    <img className="photo badge" alt="AWS badge" src={aws_badge}/>
                </a></div>
            </div>
            </div>
        </div>
    )
  }
}
